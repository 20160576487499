import React from 'react'
import styled from 'styled-components'
import Layout from '../layouts'
import { colors } from '../components/theme'
import PageWrapper from '../components/PageWrapper'
import PageIntro from '../components/PageIntro'
import Collapse from '../components/Collapse'
import TextBlock from '../components/TextBlock'
import Link from '../components/common/Link'
import MetaHeader from '../components/MetaHeader'

import imgVentureDeals from '../images/resources/venture-deals.jpg'
import imgLeanStartup from '../images/resources/lean-startup.jpg'
import imgMasterVC from '../images/resources/master-vc-game.jpg'

const ResourcesPage = () => (
  <Layout>
    <MetaHeader title="Resources" />

    <PageWrapper>
      <PageIntro
        title="Resources"
        subtitle="To take your first step, start with the basics"
      />

      <section>
        <h2>Defining key terms in angel investing</h2>
        <p>
          In our interviews and podcasts, at our events, and throughout our
          resources there are a number of terms relating to angel investment
          that you’ll hear come up often. Here’s a quick glossary that you can
          refer back to at any time.
        </p>

        <h3>What is an Accredited Investor?</h3>
        <p>
          An accredited or sophisticated investor is an investor with a special
          status under financial regulation laws. The definition of an
          accredited investor (if any), and the consequences of being classified
          as such, vary between countries. (Source: Wikipedia)
        </p>
        <div>
          <Collapse buttonText="Why you need to know?">
            <p>
              Understanding investor accreditation in your country will be
              incredibly important for you as an angel investor. Generally,
              securities distributed to the public must be offered under a
              prospectus; often long and tedious process for both investors and
              companies. However there are some exemptions from this rule, such
              as the accredited investor exemption which states that investors
              meeting certain standards can invest without the company being
              required to prepare a prospectus.
            </p>

            <p>
              In the US, according to the SEC, accredited investors must be one
              of the following:
            </p>
            <ul>
              <li>
                Individuals with income over $200K (individually) or $300K (with
                spouse) over the last 2 years and an expectation of the same
                this year
              </li>
              <li>
                Individuals with net assets over $1 million, excluding the
                primary residence (unless more is owed on the mortgage than the
                residence is worth)
              </li>
              <li>
                An institution with over $5 million in assets, such as a venture
                fund or a trust
              </li>
              <li>An entity made up entirely of accredited investors</li>
              <li>
                If you do not fall under an exemption in your country (ie; if
                you aren’t an accredited investor) it’s not that you are legally
                bound from investing – there are just more stringent rules, such
                as a company needing to offer a prospectus. Know that these
                rules were put in place to protect you from being taken
                advantage and from undertaking unnecessary risk. If you are not
                yet at the status of being an accredited investor, getting to
                that status should be your first goal. Accreditation rules in
                Ontario are very similar to the US. In Ontario however,
                non-accredited investors who are close friends/family to
                directors, senior officers or control persons of the companies
                do not need to be accredited in order to invest.
              </li>
            </ul>
          </Collapse>
        </div>
        <h3>What is an Angel Investor?</h3>
        <p>
          An angel investor or angel (also known as a business angel or informal
          investor or angel funder) is an affluent individual who provides
          capital for a business start-up, usually in exchange for convertible
          debt or ownership equity. (Source: Wikipedia)
        </p>
        <div>
          <Collapse buttonText="Why you need to know?">
            <p>
              This is you! Angel investors provide funds to startups in exchange
              for equity and or debt. Understanding this relationship is the
              first building block as your journey as an angel investor.
            </p>
          </Collapse>
        </div>
      </section>

      <hr />

      <section>
        <h2>Glossary</h2>
        <Collapse buttonText="Capitalization Table">
          <p>
            A Capitalization table (or cap table) is a table providing an
            analysis of the founders’ and investors’ percentage of ownership,
            equity dilution, and value of equity in each round of investment.
            (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            As an investor you will generally receive debt or equity in exchange
            for your funds. Your equity in the companies you have invested in
            will be represented in the capitalization table of those companies,
            showing your ownership in terms of number of shares and percentage
            of outstanding shares.
          </p>
          <p>
            Companies don’t have a standard number of shares, however one
            million shares would be a pretty standard number. New shares can be
            issued from time to time and the number of shares within a company
            is fairly irrelevant. What matters is what percentage of the total
            number of shares in the company your shares represent. A cap table
            will show you what percentage of the company your shares represent
            and will also list off all other shareholders in the company, their
            ownership and for each past investor, the cap table can show how
            much of each class was bought and how many shares of that class are
            owned as a result.
          </p>
          <p>
            You can learn a lot about a company by looking at its cap table. As
            an investor you will want to see a company’s cap table prior to
            investing. Among many others, some warning signs you could identify
            by reading a cap table would be higher valuations in past round (aka
            a down round), low percentage of ownership for founders (which could
            result in low motivation) and inactive founders (people no longer
            active in the company holding large equity stakes, acting as dead
            weight on the cap table).
          </p>
        </Collapse>
        <Collapse buttonText="Cash-on-Cash Return">
          <p>
            In investing, the cash-on-cash return is the ratio of annual
            before-tax cash flow to the total amount of cash invested, expressed
            as a percentage. It is often used to evaluate the cash flow from
            income-producing assets. Generally considered a quick napkin test to
            determine if the asset qualifies for further review and analysis.
            Cash on Cash analyses are generally used by investors looking for
            properties where cash flow is king, however, some use it to
            determine if a property is underpriced, indicating instant equity in
            a property. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            You may hear other angels or venture capitalists refer to cash on
            cash returns. When you hear this, all they are referring to is the
            multiple they made on their initial investment. For example, I
            invested $20K and got back $60K, I had a 3x cash on cash return.
          </p>
        </Collapse>
        <Collapse buttonText="Convertible Debt">
          <p>
            A convertible bond or convertible note (or a convertible debenture
            if it has a maturity of greater than 10 years) is a type of bond
            that the holder can convert into a specified number of shares of
            common stock in the issuing company or cash of equal value. It is a
            hybrid security with debt- and equity-like features. (Source:
            Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            Convertible debt is a very popular way for companies to raise angel
            financing. Its very important that you understand the difference
            between convertible notes and priced equity rounds. You will also
            need to understand the key terms of a convertible note.
          </p>
          <p>
            First off, investing through a convertible note does not immediately
            give you shares in a company, which a priced round would. A
            convertible note is technically a loan that will convert to equity
            in a company when one of a number of triggers happen. Triggers
            usually include a company raising more financing above a certain
            dollar value or the note maturing (based on a certain date specified
            in the note).
          </p>
          <p>
            Convertible notes are fairly standard in terms of structure but have
            different valuation caps, discounts, interest rates, and maturity
            dates depending on the deal. A cap in the context of a convertible
            note is the highest valuation at which your investment would convert
            to equity, the discount is the amount off the valuation at the time
            of conversion that you will receive as an early investor. The cap is
            meant to protect you from converting at a very high valuation if the
            company raises at a very high valuation in their next round of
            financing.
          </p>
          <p>
            Convertible notes are very popular because they avoid the need to
            price a company at the time of an early stage deal, which can be
            very difficult, and they are also much faster and cheaper to execute
            than priced rounds which require much more documentation.
          </p>
        </Collapse>
        <Collapse buttonText="Crowdfunding">
          <p>
            Crowdfunding is the practice of funding a project or venture by
            raising monetary contributions from a large number of people,
            typically via the internet. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            As an investor it’s important to understand funding options that
            companies have so that you can assist your companies in finding
            alternative sources of capital and so that you understand an
            entrepreneur’s BATNA to raising money from you. You may recommend
            that companies you’re invested in use these platforms to raise
            future rounds, or you may personally use these platforms to find and
            source deals.
          </p>
          <p>
            Crowdfunding via platforms like Kickstarter and Indiegogo is a great
            example of non-dilutive capital, money that can be raised without
            giving up any equity. There are also new crowdfunding platforms
            emerging that crowdfund equity financing rounds for startups,
            platforms like FundersClub and AngelList, however many regions still
            place significant restrictions on equity crowdfunding.
          </p>
          <p>
            For Canadians, it is useful to know that recently the securities
            regulators of British Columbia, Saskatchewan, Manitoba, Québec, New
            Brunswick and Nova Scotia have implemented, or expect to implement,
            registration and prospectus exemptions that will allow start-ups and
            early‑stage companies to raise capital through crowdfunding in these
            jurisdictions, subject to certain conditions.
          </p>
        </Collapse>
        <Collapse buttonText="Dilution">
          <p>
            Stock dilution is an economic phenomenon resulting from the issue of
            additional common shares by a company. This increase in the number
            of shares outstanding can result from a primary market offering
            (including an initial public offering), employees exercising stock
            options, or by conversion of convertible bonds, preferred shares or
            warrants into stock. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            Let’s say you make an investment of $10K for 1% of a company in its
            first round of financing – it’s important to understand you will not
            forever own 1% of the company. Every time the company issues
            additional shares or securities convertible into shares, your %
            ownership will decrease proportional to the raise. For example, in
            the case of the $10K investment referred to above, if the same
            company then gives away 10% of the company for $5M in their next
            round of financing, your % ownership on a fully diluted basis would
            decrease to .9%.
          </p>
          <p>
            Note that while the definition provided above only refers to common
            stock, dilution can be extended to all classes of shares.
          </p>
        </Collapse>
        <Collapse buttonText="Dividend">
          <p>
            A dividend is a payment made by a corporation to its shareholders,
            usually as a distribution of profits. When a corporation earns a
            profit or surplus, it can re-invest it in the business (called
            retained earnings), and pay a fraction of this reinvestment as a
            dividend to shareholders. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            While most startup investing results in returns in the form of
            acquisitions or IPOs, sometimes companies grow to be profitable and
            choose to remain private, for at least some amount of time. When the
            company has profits at the end of the year, the company’s board of
            directors can choose to hold those profits, reinvest them in the
            business, or to distribute them among shareholders. In some of your
            investments you may receive a portion of your return in the form of
            dividends. Dividends don’t just get issued to shareholders of
            private companies, they can also be issued by public companies – but
            dividends relating to private companies are likely more relevant to
            you as an angel investor.
          </p>
          <p>
            Note that dividends can be paid out to shareholders of a certain
            class (for example Class A Prefs) to the exclusion of other classes
            of shares in the Company – it’s a good thing to review the articles
            of a company to learn more about dividends on a particular class of
            shares.
          </p>
        </Collapse>
        <Collapse buttonText="Initial Public Offering (IPO)">
          <p>
            Initial public offering (IPO) or stock market launch is a type of
            public offering in which shares of stock in a company usually are
            sold to institutional investors that in turn sell to the general
            public, on a securities exchange, for the first time. (Source:
            Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            IPOs are generally seen as the most sought after liquidation event
            for startups. That is not to say that you can’t make very large
            returns off of companies being acquired, but many investors and
            entrepreneurs dream of creating self sustaining long term
            businesses, and the way liquidity is achieved for this type of
            company is usually through an IPO – where shares are priced and
            opened up to the general public and investors can begin selling
            their shares into the public market to realize their return. In this
            case, the securities would be traded publicly on a stock exchange
            such as the NYSE in the US or the TSX in Canada. After an IPO the
            company would be subject to more stringent rules such as continuous
            disclosure obligations
          </p>
        </Collapse>
        <Collapse buttonText="Internal Rate of Return (IRR)">
          <p>
            The internal rate of return (IRR) or economic rate of return (ERR)
            is a rate of return used in capital budgeting to measure and compare
            the profitability of investments. It is also called the discounted
            cash flow rate of return (DCFROR) (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            Where most venture capital firms measure their returns based on
            multiples with little regard for the time required to makes that
            return (ie; we made 4x on a $100K investment), it can sometimes be
            in an angel investor’s interest to weigh the pros and cons of
            multiples vs. how long their cash is tied up in a company. The best
            way to track this is through IRR. The IRR makes it easy to measure
            the profitability of your investment and to compare one investment’s
            profitability to another.
          </p>
        </Collapse>
        <Collapse buttonText="Liquidation">
          <p>
            Liquidation is the process by which a company (or part of a company)
            is brought to an end, and the assets and property of the company are
            redistributed. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            In order to get returns as an angel investor you will be relying on
            your investments reaching a liquidity event. The main exception to
            this would be if you are invested in a highly profitable venture
            that remains privately held and simply provides returns by issuing
            dividends to shareholders. The most common liquidation event for
            companies is acquisitions, where a larger company acquires a startup
            for a specific price – under a variety of conditions, and proceeds
            are distributed to investors. A less frequent, but more highly
            sought after liquidation event would be an IPO, where a startups
            shares are priced and offered on the public market. As an investor,
            you want to help your investments optimize for the best possible
            liquidation event, that will provide the highest multiple on your
            initial investment.
          </p>
        </Collapse>
        <Collapse buttonText="Limited Partnership (LP)">
          <p>
            Two or more partners united to conduct a business jointly, and in
            which one or more of the partners is liable only to the extent of
            the amount of money that partner has invested. Limited partners do
            not receive dividends, but enjoy direct access to the flow of income
            and expenses. (Source: Investopedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            If you have ever wondered where venture capital firms get their
            money to invest from, it is from Limited Partnerships (LPs) who
            invest directly into the fund. Setting up LPs rather than investing
            directly personally can protects the investors in certain
            circumstances from liability and can be used for income tax planning
            purposes. Limited partners who engages in managing the limited
            partnership can lose that partner’s limited liability. Setting up a
            corporation is another way to protect an investor from personal
            liability.
          </p>
        </Collapse>
        <Collapse buttonText="Options">
          <p>
            An option is a contract which gives the buyer (the owner) the right,
            but not the obligation, to buy or sell an underlying asset or
            instrument at a specified strike price on or before a specified
            date. The seller has the corresponding obligation to fulfill the
            transaction – that is to sell or buy – if the buyer (owner)
            “exercises” the option. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            Issuing options is generally used as a way of getting or retaining
            key talent for a company. It can also be used as a way of
            incentivizing advisors or as giving additional equity back to a
            founder who the board believes has been diluted too heavily in
            previous rounds of investment. Where options may come up in your
            negotiations as an investor is when you are determining the size of
            an option pool. When option pools are added after you invest, your
            shares get diluted on an ownership basis. For example, if a new 10%
            option pool is created, your ownership will go down by 10%. It is
            because of this dilution that investors generally ask that option
            pools are created prior to their investment, so that only the
            founders experience the dilution. This is only usually relevant in
            negotiations priced rounds. In these negotiations, investors usually
            want a large option pool created, so that they aren’t diluted by
            future options being created, and founders want smaller option pools
            so that they limit their dilution now and so that investors have to
            share in the dilution if new options need to get created in the
            future.
          </p>
        </Collapse>
        <Collapse buttonText="Participating Preferred Shares">
          <p>
            Participating preferred stock is preferred stock which provides a
            specific dividend that is paid before any dividends are paid to
            common stockholders, and which takes precedence over common stock in
            the event of a liquidation. This form of financing is used by
            private equity investors and venture capital firms. Holders of
            participating preferred stock get both their money back (with
            interest) and the money that is distributable with respect to the
            percentage of common shares into which their preferred stock can
            convert. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            As an entrepreneur, seeing participating preferred shares in a deal
            is a bit scary. That said, participating preferred shares are very
            much in an investor’s interest. This type of preferred shares
            (defined in full under Preferred Shares in the glossary) essentially
            allows an investor to double dip. At a liquidation event the
            investor gets their money back off the top, and then their pro rata
            share of the remainder. This isn’t so bad when a company has a very
            strong exit, because the relative benefit the investor gets for
            getting their return of the top of the deal is small, but it is
            really bad for entrepreneurs when they have small exits. Take for
            example a company that has raised $10M, and whose investors own 30%
            of the company. If the company were to sell for $20M, the investors
            would first get back their $10M and then take 30% of the remaining
            $10M. Resulting in investors getting $13M and other shareholders
            splitting $7M. More founder friendly investors tend to not ask for
            participating preferred shares, more risk averse investors, or
            investors that have leverage in negotiations may ask for this type
            of shares.
          </p>
        </Collapse>
        <Collapse buttonText="Preferred Shares">
          <p>
            Preferred shares (also called preferred stock, preference shares or
            simply preferreds) is a type of stock which may have any combination
            of features not possessed by common stock including properties of
            both an equity and a debt instrument, and is generally considered a
            hybrid instrument. Preferreds are senior (i.e., higher ranking) to
            common stock, but subordinate to bonds in terms of claim (or rights
            to their share of the assets of the company)[1] and may have
            priority over common stock (ordinary shares) in the payment of
            dividends and upon liquidation. Terms of the preferred stock are
            described in the articles of association. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            In short, you would rather have preferred stock in a company than
            common stock. Common stock is the type of stock initially issued to
            founders, preferred stock is a higher class of stock, generally
            issued in funding rounds that grants investors special rights. For
            example the right to get paid back first in the case of a
            liquidation event for the company. The terms of the pref stock are
            described in the “articles of incorporation.”
          </p>
        </Collapse>
        <Collapse buttonText="Shareholders Agreement">
          <p>
            An arrangement among a company’s shareholders describing how the
            company should be operated and the shareholders’ rights and
            obligations. It also includes information on the regulation of the
            shareholders’ relationship, the management of the company, ownership
            of shares and privileges and protection of shareholders. (Source:
            Investopedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            While a term sheet will highlight the key points of an agreement, it
            is ultimately the shareholders agreement that will formalize the
            majority of your rights as an investor. Shareholders agreements
            include much more detail, and will usually be drafted by and
            reviewed by yours and the company’s legal counsel after a high level
            agreement is reached between you and the company in the term sheet
            stage. That said, it’s important that you review and understand the
            shareholders agreement as well as you do the term sheet, as this is
            the ultimate agreement that will hold up in court and in all further
            conversations once the financing is completed. In certain
            circumstances a shareholders agreement can set out board nominees
            who can be nominated by a majority of a certain class or by a
            particularly large institutional investor
          </p>
          <p>
            Some companies they may not have a “shareholders agreement” but
            rather similar provisions included in a voting agreement, investor
            rights agreement, right of first refusal and co-sale agreement.
          </p>
        </Collapse>
        <Collapse buttonText="Super Angel">
          <p>
            Super angel (or “super-angel”) investors are a group of serial
            investors in early stage ventures in Silicon Valley, California and
            other technology centers who are particularly sophisticated,
            insightful, or well-connected in the startup business community.
            (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            Super angels are an interesting breed, in many ways they are similar
            to early stage venture capital firms, simply due to the size of
            check they are able to write, the number of deals they do, or the
            type of doors they are able to open for company. For examples of
            ‘super angels’ you can check out Forbes list of “The Most
            Influential Angel Investors On AngelList”. As you are learning about
            angel investing, it would be good to follow some high profile super
            angels to learn from their investment strategies and networks.
          </p>
        </Collapse>
        <Collapse buttonText="Term Sheet">
          <p>
            A term sheet is a bullet-point document outlining the material terms
            and conditions of a business agreement. After a term sheet has been
            “executed”, it guides legal counsel in the preparation of a proposed
            “final agreement”. It then guides, but is not necessarily binding,
            as the signatories negotiate, usually with legal counsel, the final
            terms of their agreement. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            While you won’t necessarily see term sheets for every investment you
            make you will likely come across many term sheets when looking at
            companies raising a round of financing from multiple investors —
            either in a priced round of financing or through convertible notes.
            Term sheets are a simplified way of understanding all of the key
            points of a deal, like valuation and your rights as an investor.
            Generally most high level negotiation will happen at the term sheet
            level, before lawyers go in and draft the final agreements. While
            term sheets are non binding, it is not good practice to agree to or
            sign a term sheet when you do not agree on all points. Term sheets
            often include a “confidentiality” clause which the parties are bound
            by – this ensures that the due diligence process can be conducted
            without risk of breaching the company’s confidentiality. As you see
            more and more term sheets you will get better at pulling out key
            information and will start to notice when anything unusual or
            non-standard.
          </p>
        </Collapse>
        <Collapse buttonText="Venture Capitalist (VC)">
          <p>
            An investor who either provides capital to startup ventures or
            supports small companies that wish to expand but do not have access
            to public funding. Venture capitalists are willing to invest in such
            companies because they can earn a massive return on their
            investments if these companies are a success. Venture capitalists
            also experience major losses when their picks fail, but these
            investors are typically wealthy enough that they can afford to take
            the risks associated with funding young, unproven companies that
            appear to have a great idea and a great management team. (Source:
            Investopedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            As an angel investor you will usually invest in companies before
            they raise money from VCs as VCs generally participate in later
            stage rounds of funding. When investing you will need to ensure that
            you are structuring deals in a way that still leaves room in the cap
            table for further VC rounds and that doesn’t over or under value a
            company — all of which could lead to making the company you are
            investing in unattractive to future VCs. You will also want to try
            to reserve the right to preserve your equity stake in your
            investments, in future rounds so that you don’t get squeezed out of
            the cap table when larger rounds are raised from VCs. Note that in
            some VCs may not agree to other/current shareholders participating
            in a future round, despite any past agreements that have been made
            to earlier investors.
          </p>
        </Collapse>
        <Collapse buttonText="Vesting">
          <p>
            Vesting is to give an immediately secured right of present or future
            deployment. One has a vested right to an asset that cannot be taken
            away by any third party, even though one may not yet possess the
            asset. When the right, interest, or title to the present or future
            possession of a legal estate can be transferred to any other party,
            it is termed a vested interest. (Source: Wikipedia)
          </p>
          <p className="strong">Why you need to know</p>
          <p>
            Vesting will be most relevant to you as it applies to founder shares
            in their company. A common reason startups fail is founder disputes,
            someone leaves, they don’t agree on the future of the company etc.
            Vesting is a great way to protect a company from one founder leaving
            and walking away with their portion of the company a few months
            after you invest. What vesting does is it grants founders shares
            over time. A standard vesting clause would be 4 years, with a 1 year
            cliff. Meaning 25% of the shares vest after the one year anniversary
            and then shares vest monthly from that point on. For example, let’s
            say you own 10% of a company and each of its two founders own 45%.
            With a 4 year vest, 1 year cliff – if either of the founders left
            the company within the first year of the vesting agreement they
            would receive zero shares. If one founder leaves 1.5 years in they
            would receive 35.4% of their shares (15.93 % of the company), rather
            than 100% of their shares or 45% of the company if there were no
            vesting agreement. If they stay all 4 years they receive all of
            their shares. Without vesting, no matter when a founder left, they
            would get to keep 100% of their shares. Most vesting agreements
            accelerate vesting in the event of a sale, meaning that if the
            company sold 6 months after the vesting agreement was put in place,
            founders would automatically vest 100% of their shares. As an
            investor, it’s in your interest to protect your investment by asking
            founders to be put on vesting agreements. For companies with more
            than one founder, it is also very much in the interest of both
            founders to protect themselves with this type of agreement.
          </p>
        </Collapse>
      </section>

      <hr />
      <section>
        <h2>Books</h2>
        <BookContainer>
          <Book
            imgSrc={imgVentureDeals}
            title="Venture Deals"
            author="By Brad Feld and Jason Mendelson"
            link="http://www.amazon.ca/Venture-Deals-Smarter-Lawyer-Capitalist/dp/1118443616/ref=sr_1_1?ie=UTF8&qid=1436381300&sr=8-1&keywords=Venture+Deals%3A+Be+Smarter+Than+Your+Lawyer+and+Venture+Capitalist"
          />
          <Book
            imgSrc={imgLeanStartup}
            title="The Lean Startup"
            author="By Eric Ries"
            link="http://www.amazon.ca/Lean-Startup-Entrepreneurs-Continuous-Innovation/dp/0307887898/ref=sr_1_1?ie=UTF8&qid=1436381324&sr=8-1&keywords=The+Lean+Startup%3A+How+Today%27s+Entrepreneurs+Use+Continuous+Innovation+to+Create+Radically+Successful+Businesses"
          />
          <Book
            imgSrc={imgMasterVC}
            title="Mastering the VC Game"
            author="By Jeffrey Bussgang"
            link="http://www.amazon.ca/Mastering-VC-Game-Venture-Start-up/dp/1591844444/ref=sr_1_1?ie=UTF8&qid=1436381375&sr=8-1&keywords=Mastering+the+VC+Game%3A+A+Venture+Capital+Insider+Reveals+How+to+Get+from+Start-up+to+IPO+on+Your+Terms"
          />
        </BookContainer>
      </section>

      <hr />
      <section>
        <TextBlock small>
          Is there something we missed that you think would be helpful to you or
          the community? Please email{' '}
          <a href="mailto:hello@femalefunders.com">hello@femalefunders.com</a>{' '}
          and we’ll be sure to add it to the list.
        </TextBlock>
      </section>
    </PageWrapper>
  </Layout>
)

const BookContainer = styled.div`
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  grid-gap: 2rem;
`

const Book = styled(({ className, imgSrc, title, author, link }) => (
  <div className={className}>
    <Link href={link}>
      <img src={imgSrc} alt="" />
      <div className="title">{title}</div>
      <div className="author">{author}</div>
    </Link>
  </div>
))`
  img {
    margin: 0 0 0.75rem;
    max-height: 10rem;
    object-fit: contain;
    object-position: 0 0;
  }
  .title {
    font-size: 1.125rem;
    font-weight: 600;
  }
  .author {
    font-size: 0.875rem;
  }

  a {
    text-decoration: none;
    color: ${colors.primaryText};
  }
`

export default ResourcesPage
