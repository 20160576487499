import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from './theme'
import DropdownArrow from './DropdownArrow'

const Arrow = styled(DropdownArrow)`
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
`
const CollapseButton = styled.button`
  padding: 0.5rem 0;
  font-size: 1.25rem;
  background: transparent;
  border: 1px solid transparent;
  color: ${colors.primaryText};
  width: 100%;
  text-align: left;
  text-shadow: none;

  .dropdown-arrow {
    transform: rotateZ(-90deg);
  }

  &:hover,
  &:focus {
    background: ${colors.white};
    color: ${colors.primaryText};

    .dropdown-arrow {
      transform: translateX(0.125rem) rotateZ(-90deg);
    }
  }

  &.active {
    .dropdown-arrow {
      transform: rotateZ(0deg);
    }
  }
`
const CollapseContainer = styled.div`
  padding: 0.75rem 1.5rem;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  background: ${colors.white};

  &:last-of-type .active {
    border-bottom: none;
  }
`
const CollapseContent = styled.div`
  display: none;
  padding: 0.5rem 0.5rem 1rem 1.375rem;

  &.active {
    border-bottom: 1px solid ${colors.borderLight};
    display: block;
    animation: fadeInDownSmall 0.25s ease-out;
  }

  .strong {
    font-weight: 600;
    margin: 0 0 0.5rem;
  }

  > *:last-of-type {
    margin-bottom: 0;
  }
`

const Collapse = ({ buttonText, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <CollapseContainer>
      <CollapseButton
        onClick={() => setIsOpen(!isOpen)}
        className={isOpen && 'active'}>
        <Arrow className="dropdown-arrow" />
        {buttonText}
      </CollapseButton>

      <CollapseContent className={isOpen && 'active'}>
        {children}
      </CollapseContent>
    </CollapseContainer>
  )
}

export default Collapse
